import React, { useEffect, useState } from "react";
import { IAnswerMapping, ICondition, ITransformation } from "../../domain/mappingTypes";
import { MappingContext } from "../../context/mappingDataContext";
import { SBox, SCol, SIcon, SRow, SAlert } from "@avalara/skylab-sdk/react";
import { OptionsData } from "@avalara/skylab-sdk";
import uuid from "react-uuid";

import "./styles.scss";

import { AnimatePresence, motion } from "framer-motion";
import RadioPill from "../../ui/RadioPill/RadioPill";
import ByValueComponent from "./ByValueComponent";
import ByOperationComponent from "./ByOperationComponent";
import ByConcatenationComponent from "./ByConcatenationComponent";
import ByFlipSignageComponent from "./ByFlipSignageComponent";
import Conditions from "./ConditionsComponent";
import ByCopyComponent from "./ByCopyComponent";

type IProps = {
  item: IAnswerMapping;
  transformationData: ITransformation;
  deleteClick: () => void;
};

const pillOptions = [
  { label: "By Value", value: "byValue", type: "default" },
  { label: "By Flip signage", value: "byFlipSignage", type: "selectColumn" },
  { label: "By Operation", value: "byOperation", type: "newColumn" },
  { label: "By Concatenation", value: "byConcatenation", type: "newColumn" },
  { label: "By Copy", value: "byCopy", type: "newColumn" },
];

const flipSignageMessages = {
  flipSignage: {
    mainMessage: "Invert the signage in column",
    explanation: "(Negative values will become positive and positive values will become negative)",
  },

  alwaysPositive: {
    mainMessage: "Always convert to a POSITIVE number, values in the column",
    explanation:
      "(If it is already positive, it will remain positive. If it is negative, it will be turned into a positive)",
  },
  alwaysNegative: {
    mainMessage: "Always convert to a NEGATIVE number, values in the column",
    explanation:
      "(If it is already negative, it will remain negative. If it is positive, it will be turned into a negative)",
  },
};

export const variantsSlideDown = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 },
};

type FlipSignageType = keyof typeof flipSignageMessages;

const TransformationComponent = ({ item, deleteClick, transformationData }: IProps) => {
  const { setFromTo, fromTo, headers } = MappingContext();
  const [selectColumnOption, setSelectColumnOption] = useState<OptionsData[]>([]);

  const [transformation, setTransformation] = useState<ITransformation>(transformationData);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const columnOptions = headers.map((header) => {
      const newList: OptionsData = {
        label: header.name,
        value: header.name,
        selected: false,
      };
      return newList;
    });
    columnOptions.unshift({
      label: "Select...",
      value: "",
    });
    setSelectColumnOption(columnOptions);
  }, [headers]);

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformation]);

  const updateData = () => {
    const transformationsToUpdate = item.transformations?.map((t) => {
      if (t.id === transformation.id) return transformation;
      else return t;
    });

    const itensToUpdate = fromTo.map((i) => {
      if (i.id === item.id)
        return {
          ...i,
          transformations: i.transformations ? transformationsToUpdate : [transformation],
        };
      else return i;
    });

    setFromTo(itensToUpdate);
  };

  const addCondition = () => {
    const newItem = {
      id: uuid(),
      operator: {
        value: 4,
      },
      type: "String",
      grouping: "Or",
      selectOptionList: [...selectColumnOption],
    } as ICondition;

    setTransformation((t) => {
      return {
        ...t,
        conditions: t.conditions ? [...t.conditions, newItem] : [newItem],
      };
    });
  };

  const handleChangeOperation = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTransformation((t) => {
      return { ...t, replaceOperation: e.target.value };
    });
  };

  const handleChangeFlipSignagetype = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransformation((t) => {
      return { ...t, flipSignageType: e.target.value };
    });
  };

  const handleChangeSelectOperation = (field: string, value: string, listName: string) => {
    const updatedOptions = selectColumnOption.map((item) => {
      return { ...item, selected: item.value === value };
    });
    const newTransformation = { ...transformation, [field]: value, [listName]: updatedOptions };
    setTransformation(newTransformation);
  };

  const handleChangeSelectConcatenation = (value: string, listsIndex: number) => {
    const updatedOptions = selectColumnOption.map((item) => {
      return { ...item, selected: item.value === value };
    });

    const _concatValues = [...transformation.concatenation!.values!];
    const _selectOptionListConcat = transformation.concatenation!.selectOptionList!.map(
      (subArray) => [...subArray]
    );

    _selectOptionListConcat[listsIndex] = updatedOptions;
    _concatValues[listsIndex] = value;

    const newTransformation = {
      ...transformation,
      concatenation: {
        ...transformation.concatenation,
        selectOptionList: _selectOptionListConcat,
        values: _concatValues,
      },
    };
    setTransformation(newTransformation);
  };

  const handleChangeReplaceType = (value: string) => {
    if (value === transformation.replaceType) return;

    const selectOptionList1 = selectColumnOption;
    const selectOptionList2 = selectColumnOption;
    const selectOptionList = [selectColumnOption, selectColumnOption];

    setTransformation((trasnformation) => {
      return {
        ...trasnformation,
        value: "",
        replaceValueComplement: "",
        flipSignageType: "flipSignage",
        replaceType: value,
        replaceOperation: "+",
        selectOptionList1,
        selectOptionList2,
        concatenation: {
          selectOptionList,
          values: ["", ""],
          isInput: [false, false],
        },
      };
    });
  };

  const toggleCollapse = () => {
    setIsCollapsed((previousState) => !previousState);
  };

  const showMensage =
    item.transformations?.length > 1 &&
    !(transformation.conditions !== undefined && transformation.conditions?.length > 0);

  const blockCollapse =
    transformation.value &&
    transformation.conditions?.every(
      (c) => c.column && +c.operator.value >= 1 && (c.value || [1, 2].includes(+c.operator.value))
    );

  const flipSignageVariants = {
    hidden: {
      opacity: 0,
      x: transformation.replaceType === "byFlipSignage" ? -50 : 50,
      height: 70,
    },
    visible: {
      opacity: 1,
      x: 0,
      height: transformation.replaceType === "byFlipSignage" ? 120 : 50,
    },
  };

  const flipSignageMessageIndex = transformation.flipSignageType as FlipSignageType;

  return (
    <SBox className="pad-all-none margin-bottom-sm">
      <div className="header-buttons" style={{ height: "46px", padding: "14px" }}>
        {!isCollapsed ? (
          <button
            aria-label="toggle down"
            type="button"
            data-testid="toggle_down_id"
            disabled={!blockCollapse}
            onClick={toggleCollapse}
          >
            <SIcon name="chevron-down" />
          </button>
        ) : (
          <button
            aria-label="toggle down"
            type="button"
            data-testid="toggle_down_id"
            onClick={toggleCollapse}
          >
            <SIcon name="chevron-right" />
          </button>
        )}
        <div className={`collapsible-header ${isCollapsed ? "" : "collapsible-header-hidden"}`}>
          {item.to}
        </div>
        <button
          aria-label="delete transformation"
          type="button"
          data-testid="button_delete_transformation_id"
          className="secondary"
          onClick={deleteClick}
        >
          <SIcon name="close-square" />
        </button>
      </div>

      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <RadioPill
            id={transformation.id}
            pillOptions={pillOptions}
            transformationType={item.isCustomColumn ? "newColumn" : "selectColumn"}
            selectedValue={transformation.replaceType!}
            onChangeReplaceType={handleChangeReplaceType}
          />
        )}
      </AnimatePresence>

      <motion.div
        animate={{ opacity: isCollapsed ? 0 : 1, height: isCollapsed ? 0 : "auto" }}
        transition={{ duration: 0.4 }}
      >
        <>
          <SRow className={`tranformation-header ${isCollapsed ? "" : "margin-top-lg"}`}>
            <SCol span="12" className="flex justify-content-center pad-all-none">
              <AnimatePresence>
                {item.isCustomColumn && (
                  <>
                    <div className="header-info">
                      Replace the value in column{" "}
                      <span className="hightlight-column">"{item.to}"</span> with
                    </div>
                    <div>
                      <motion.div
                        initial={{ width: 165, height: "auto", position: "relative" }}
                        animate={{
                          width: transformation.replaceType === "byOperation" ? 403 : 165,
                        }}
                        transition={{ duration: 0.2 }}
                      >
                        <ByValueComponent
                          transformation={transformation}
                          item={item}
                          setTransformation={setTransformation}
                        />

                        <ByOperationComponent
                          transformation={transformation}
                          item={item}
                          onChangeOperation={handleChangeOperation}
                          onChangeSelectOperation={handleChangeSelectOperation}
                        />

                        <ByConcatenationComponent
                          transformation={transformation}
                          item={item}
                          headers={headers}
                          selectColumnOption={selectColumnOption}
                          setTransformation={setTransformation}
                          onChangeSelectConcatenation={handleChangeSelectConcatenation}
                        />

                        <ByCopyComponent
                          transformation={transformation}
                          item={item}
                          selectColumnOption={selectColumnOption}
                          setTransformation={setTransformation}
                        />
                      </motion.div>
                    </div>
                  </>
                )}
              </AnimatePresence>

              <AnimatePresence initial={false}>
                {!item.isCustomColumn && (
                  <motion.div
                    key={transformation.replaceType}
                    initial="hidden"
                    animate="visible"
                    variants={flipSignageVariants}
                  >
                    {transformation.replaceType === "byFlipSignage" ? (
                      <>
                        <div className="header-info center-info">
                          {flipSignageMessages[flipSignageMessageIndex].mainMessage}{" "}
                          <span className="hightlight-column">"{item.to}"</span>
                          <div className="small-info">
                            {flipSignageMessages[flipSignageMessageIndex].explanation}
                          </div>
                        </div>

                        <ByFlipSignageComponent
                          handleChangeFlipSignagetype={handleChangeFlipSignagetype}
                          transformation={transformation}
                        />
                      </>
                    ) : (
                      <div className="flex justify-content-center pad-all-none">
                        <div className="header-info">
                          Replace the value in column{" "}
                          <span className="hightlight-column">"{item.to}"</span> with
                        </div>
                        <div>
                          <ByValueComponent
                            transformation={transformation}
                            item={item}
                            setTransformation={setTransformation}
                          />
                        </div>
                      </div>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </SCol>
          </SRow>

          <div className="text-center">
            <button
              type="button"
              data-testid={"button-add-conditions-" + item.from.columnName}
              className="ghost-blue icon-leading margin-top-md margin-bottom-md"
              onClick={addCondition}
            >
              <SIcon name="plus"></SIcon>
              Add conditions
            </button>
          </div>

          <AnimatePresence>
            {transformation.conditions?.length >= 1 && (
              <motion.div
                variants={variantsSlideDown}
                initial="open"
                animate="open"
                exit="collapsed"
              >
                <div className="separator margin-all-lg margin-top-none">When</div>
              </motion.div>
            )}
          </AnimatePresence>

          <Conditions
            item={item}
            transformation={transformation}
            selectColumnOption={selectColumnOption}
            setTransformation={setTransformation}
          />

          {showMensage && (
            <SRow className="margin-left-md margin-right-md">
              <SCol span="12">
                <SAlert status="info" noDismiss>
                  <div>
                    When multiple Transformations have the same Field, Conditions are required.
                  </div>
                  <input type="text" name="conditions required" required hidden />
                </SAlert>
              </SCol>
            </SRow>
          )}
        </>
      </motion.div>
    </SBox>
  );
};

export default TransformationComponent;
