import React, { useEffect, useState } from "react";

import { SAlert } from "@avalara/skylab-sdk/react";
import { MappingContext } from "../../../context/mappingDataContext";
import { motion } from "framer-motion";
import {
  getColumnNamesByCountryAndType,
  getFullCountryData,
  getFillingTypes,
} from "../../../tools/CountriesTools";

import "./styles.scss";
import PageHeader from "../../../components/PageHeader/PageHeader";

type Country = {
  name: string;
  isChecked: boolean;
  fillingType: string[];
};

const SetCountries = ({ previousStep }: { previousStep?: number }) => {
  const {
    fromTo,
    fromToHistory,
    selectedTypes,
    selectedCountries,
    isEditMode,
    setSelectedTypes,
    setSelectedCountries,
    setFromData,
    updateFromTo,
  } = MappingContext();

  const [allContries, setAllContries] = useState<Country[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [types, setTypes] = useState([{ name: "", isChecked: false }]);

  useEffect(() => {
    const allCountriesParsed = getFullCountryData().map((country) => {
      return {
        name: country.country,
        isChecked: manageIsChecked("country", country.country),
        fillingType: country.fillingType as string[],
      };
    });
    const allTypesParsed = getFillingTypes().map((type) => {
      return { name: type, isChecked: manageIsChecked("type", type) };
    });

    const checkedType = isEditMode
      ? selectedTypes[0]
      : allTypesParsed.filter((type) => type.isChecked).map((type) => type.name)[0];

    const countriesFiltered = allCountriesParsed.filter((country) =>
      country.fillingType.includes(checkedType)
    );
    setAllContries(allCountriesParsed);
    setCountries(countriesFiltered);
    setTypes(allTypesParsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const manageIsChecked = (type: string, name: string): boolean => {
    // Setting type VAT as default type temporally, in the future, the line below will be removed
    if (previousStep === 0 && type === "type" && name === "VAT" && !isEditMode) return true;

    if (type === "type") {
      return selectedTypes.includes(name);
    }
    if (type === "country") {
      return selectedCountries.includes(name);
    }
    return false;
  };

  useEffect(() => {
    const checkedCountries = countries.filter((c) => c.isChecked).map((c) => c.name);
    const checkedType = types.filter((type) => type.isChecked).map((type) => type.name);

    const mappingColumns = getColumnNamesByCountryAndType(checkedCountries, checkedType);
    const mappingColumnsWithoutRequiredFor = mappingColumns.map((column) => {
      const { requiredFor, ...rest } = column;
      return rest;
    });

    setSelectedTypes(checkedType);
    if (checkedCountries.length > 0) {
      setFromData(mappingColumnsWithoutRequiredFor);
      updateFromTo(mappingColumnsWithoutRequiredFor, fromTo, fromToHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, types]);

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    updateTypes(value, checked);
    const countriesFiltered = allContries.filter((country) => country.fillingType.includes(value));
    setCountries(countriesFiltered);
  };

  const updateTypes = (name: string, isChecked: boolean) => {
    const itensToUpdate = types.map((i) => {
      if (i.name === name) return { name: name, isChecked: isChecked };
      else return { name: i.name, isChecked: false };
    });
    const countriesFiltered = allContries.filter((country) => country.fillingType.includes(name));

    setTypes(itensToUpdate);
    saveSelectedContries(countriesFiltered);
  };

  const updateCountries = (name: string, isChecked: boolean) => {
    const checkedType = types.filter((type) => type.isChecked).map((type) => type.name)[0];
    const allSelectedCountries = allContries.map((i) => {
      if (i.name === name) return { name: name, isChecked: isChecked, fillingType: i.fillingType };
      else return i;
    });
    const countriesFiltered = allSelectedCountries.filter((country) =>
      country.fillingType.includes(checkedType)
    );

    setAllContries(allSelectedCountries);
    setCountries(countriesFiltered);
    saveSelectedContries(countriesFiltered);
  };

  const saveSelectedContries = (_countries: Country[]) => {
    const selectedContries = _countries.filter((c) => c.isChecked).map((c) => c.name);
    setSelectedCountries(selectedContries);
  };

  return (
    <>
      <PageHeader headerTitle="Import settings" />
      <SAlert id="error-alert" status="info" noDismiss>
        <div>We need these settings to process your transactions.</div>
      </SAlert>

      <h4>Which return types and countries do you wish to map to?</h4>

      <p className="desc">Lists the return types you are mapping the data for</p>
      <div className="container">
        {types.map((c, index) => {
          return (
            <span key={index}>
              <input
                type="radio"
                data-testid={"radio-type-" + c.name}
                id={c.name + index}
                checked={c.isChecked}
                name="radio-type"
                value={c.name}
                onChange={onChangeRadio}
              />
              <label htmlFor={c.name + index}>{c.name.replace(/(^VAT$)/, "$1 Returns")}</label>
            </span>
          );
        })}
      </div>

      <br />
      <p className="desc">Lists the countries you are mapping the data for</p>

      <div className="container">
        {countries.map((c, index) => (
          <motion.span layout key={c.name} initial={false}>
            <input
              type="checkbox"
              data-testid={"checkbox-country-" + c.name}
              id={c.name + index}
              checked={c.isChecked}
              name={c.name}
              value={c.name}
              onChange={(e) => updateCountries(c.name, e.target.checked)}
            />
            <label htmlFor={c.name + index}>{c.name}</label>
          </motion.span>
        ))}
      </div>
    </>
  );
};

export default SetCountries;
