import { AnimatePresence, motion } from "framer-motion";
import { IAnswerMapping, ICondition, ITransformation } from "../../domain/mappingTypes";
import { SCol, SIcon, SRow } from "@avalara/skylab-sdk/react";
import { OptionsData } from "@avalara/skylab-sdk";
import { SkylabSelectWrapper } from "../../ui/FixSelectRequired/FixSelectRequired";
import { extractDataTypes } from "../../tools/schemaConversionTools";
import { MappingContext } from "../../context/mappingDataContext";

type Props = {
  item: IAnswerMapping;
  transformation: ITransformation;
  selectColumnOption: OptionsData[];
  setTransformation: React.Dispatch<React.SetStateAction<ITransformation>>;
};

const conditionTypeMapping = {
  String: "text",
  Decimal: "number",
  Integer: "number",
  DateTime: "date",
};

const conditionSelectLabelTypeMapping = {
  String: "Text",
  Integer: "Whole Number",
  Decimal: "Decimal Number",
  Boolean: "True or False",
  DateTime: "Date & Time",
};

export const variantsSlideDown = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 },
};

const Conditions = (props: Props) => {
  const { headers, operators } = MappingContext();
  const columnTypeList = extractDataTypes(operators);

  const updateOperator = (con: ICondition, field: string, value: any) => {
    const valueToUpdate = { [field]: value };
    if (value === "1" || value === "2") {
      delete con.value;
    }
    updateCondition(con, "operator", valueToUpdate);
  };

  const onChangeColumnAndType = (con: ICondition, field: string, value: any) => {
    const newCondition = { ...con };

    if (field === "column") {
      const headerData = headers.find((header) => header.name === value);
      if (headerData?.type) newCondition.type = headerData.type;
    }

    if (field === "type") {
      newCondition.operator.value = -1;
    }

    return newCondition;
  };

  const updateSelectColumn = (con: ICondition, field: string, value: any) => {
    const newCondition = { ...con };
    const updatedOptions = props.selectColumnOption.map((item) => {
      return { ...item, selected: item.value === value };
    });
    newCondition.selectOptionList = updatedOptions;
    updateCondition(newCondition, field, value);
  };

  const updateCondition = (con: ICondition, field: string, value: any) => {
    const newCondition = onChangeColumnAndType(con, field, value);

    const itensToUpdate = props.transformation.conditions.map((i) => {
      if (i.id === con.id) return { ...newCondition, [field]: value };
      else return i;
    });

    props.setTransformation((t) => {
      return { ...t, conditions: itensToUpdate };
    });
  };

  const removeConditon = (id: string) => {
    props.setTransformation((t) => {
      return { ...t, conditions: [...t.conditions.filter((c) => c.id !== id)] };
    });
  };

  return (
    <AnimatePresence>
      {props.transformation.conditions?.map((con, index) => {
        const hideInputValue =
          con.operator.value.toString() === "1" || con.operator.value.toString() === "2";
        const valueRequired = props.item.hasTransformation && !hideInputValue;
        return (
          <motion.div
            key={con.id}
            variants={variantsSlideDown}
            initial="collapsed"
            animate="open"
            exit="collapsed"
          >
            <SRow key={con.id}>
              {index > 0 && (
                <SCol span="12" className="no-bottom-padding-col">
                  <SRow className="flex-center radio-row">
                    <fieldset className="container_radio">
                      <input
                        type="radio"
                        data-testid={`radio-conditional-operator-and-${props.item.from.columnName}-${index}`}
                        id={con.id + "andId"}
                        name={con.id + "andId"}
                        checked={con.grouping === "And" || false}
                        value="And"
                        onChange={(e) => updateCondition(con, "grouping", e.target.value)}
                      />
                      <label className="no-top-margin" htmlFor={con.id + "andId"}>
                        And
                      </label>
                      <input
                        type="radio"
                        data-testid={`radio-conditional-operator-or-${props.item.from.columnName}-${index}`}
                        id={con.id + "orId"}
                        name={con.id + "orId"}
                        checked={con.grouping === "Or" || false}
                        value="Or"
                        onChange={(e) => updateCondition(con, "grouping", e.target.value)}
                      />
                      <label className="no-top-margin" htmlFor={con.id + "orId"}>
                        Or
                      </label>
                    </fieldset>
                  </SRow>
                </SCol>
              )}
              <SCol span="12" className="no-bottom-padding-col">
                <SRow className="conditions-row margin-left-sm margin-right-xl margin-bottom-xs">
                  <SCol span="4">
                    <label
                      htmlFor={`dropdown-condition-column-name-${props.transformation.id}-${index}`}
                      className="required"
                    >
                      Column:
                    </label>
                    <SkylabSelectWrapper
                      input-id={`dropdown-condition-column-name-${props.transformation.id}-${index}`}
                      id={`dropdown-condition-column-name-${props.transformation.id}-${index}`}
                      multiple={false}
                      showSelectionCount={false}
                      required={props.item.hasTransformation}
                      data-testid={`dropdown-condition-column-name-${props.item.from.columnName}-${index}`}
                      value={con.column || ""}
                      onS-select={(e: any) =>
                        updateSelectColumn(con, "column", e.detail.item.value)
                      }
                      optionsList={con.selectOptionList}
                    />
                  </SCol>
                  <SCol span="2">
                    <label className="required" htmlFor="typeId">
                      Type:
                    </label>
                    <select
                      id="typeId"
                      data-testid={`dropdown-condition-column-type-${props.item.from.columnName}-${index}`}
                      value={con.type || ""}
                      required={props.item.hasTransformation}
                      onChange={(e) => updateCondition(con, "type", e.target.value)}
                    >
                      {columnTypeList.map((columnType) => (
                        <option key={con.id + columnType} value={columnType}>
                          {conditionSelectLabelTypeMapping[
                            columnType as keyof typeof conditionSelectLabelTypeMapping
                          ] || columnType}
                        </option>
                      ))}
                    </select>
                  </SCol>
                  <SCol span="3">
                    <label className="required" htmlFor="operatorId">
                      Operator:
                    </label>
                    <select
                      id="operatorId"
                      data-testid={`dropdown-condition-operator-${props.item.from.columnName}-${index}`}
                      value={con.operator.value}
                      required={props.item.hasTransformation}
                      onChange={(e) => updateOperator(con, "value", e.target.value)}
                    >
                      <option value="">Select...</option>
                      <option value={1}>Empty Field</option>
                      <option value={2}>Not Empty Field</option>
                      {operators.map((item) => {
                        if (item.dataTypeList.find((c) => c === con.type))
                          return (
                            <option key={con.id + item.value} value={item.value}>
                              {item.description}
                            </option>
                          );
                        else return null;
                      })}
                    </select>
                  </SCol>
                  <SCol span="3" className="pad-right-md">
                    <label className={`${valueRequired ? "required" : ""}`} htmlFor="valueId">
                      Value:
                    </label>
                    {con.type !== "Boolean" ? (
                      <input
                        id="valueId"
                        data-testid={`dropdown-condition-compare-value-${props.item.from.columnName}-${index}`}
                        required={valueRequired}
                        disabled={!valueRequired}
                        type={conditionTypeMapping[con.type] || "text"}
                        value={con.value || ""}
                        onChange={(e) => updateCondition(con, "value", e.target.value)}
                      />
                    ) : (
                      <select
                        id="boolean-dropdown"
                        data-testid={`dropdown-condition-compare-value-boolean-${props.item.from.columnName}-${index}`}
                        disabled={!valueRequired}
                        value={con.value || ""}
                        required={valueRequired}
                        onChange={(e) => updateCondition(con, "value", e.target.value)}
                      >
                        <option value="">Select...</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    )}

                    <div className="trash-float-button">
                      <button
                        type="button"
                        aria-label="Delete condition"
                        data-testid={`button-condition-delete-${props.item.from.columnName}-${index}`}
                        className="ghost-blue"
                        onClick={() => removeConditon(con.id)}
                      >
                        <SIcon name="trash" className="margin-top-xs"></SIcon>
                      </button>
                    </div>
                  </SCol>
                </SRow>
              </SCol>
            </SRow>
          </motion.div>
        );
      })}
    </AnimatePresence>
  );
};

export default Conditions;
