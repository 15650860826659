import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const MapperPopover = ({
  trigger,
  content,
}: {
  trigger: React.ReactNode;
  content: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const togglePopover = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const closePopover = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
      setIsReady(false);
    }
  };

  useEffect(() => {
    const shadowRoot = containerRef.current?.getRootNode();

    if (isOpen) {
      if (shadowRoot instanceof ShadowRoot) {
        shadowRoot.addEventListener("mousedown", closePopover as EventListener);
      } else {
        document.addEventListener("mousedown", closePopover);
      }
    }

    return () => {
      if (shadowRoot instanceof ShadowRoot) {
        shadowRoot.removeEventListener("mousedown", closePopover as EventListener);
      } else {
        document.removeEventListener("mousedown", closePopover);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && containerRef.current && popoverRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();

      const calculatedTop = containerRect.height / 2 - popoverRect.height / 2;
      const calculatedLeft = -popoverRect.width - 10;

      setPosition({
        top: calculatedTop,
        left: calculatedLeft,
      });
      setIsReady(true);
    }
  }, [isOpen]);

  return (
    <div ref={containerRef} style={{ position: "relative", display: "inline-block" }}>
      {/* Trigger */}
      <div onMouseDown={togglePopover} style={{ cursor: "pointer" }}>
        {trigger}
      </div>

      {/* Dummy popover for calculation */}
      {isOpen && (
        <div
          ref={popoverRef}
          style={{
            position: "absolute",
            transformOrigin: "top right",
            padding: "8px",
            top: "-9999px",
            left: "-9999px",
            visibility: "hidden",
          }}
        >
          {content}
        </div>
      )}

      {/* Popover */}
      <AnimatePresence>
        {isOpen && isReady && (
          <motion.div
            initial={{
              opacity: 0,
              translateX: "20%",
            }}
            data-testid="mapper-popover"
            animate={{ opacity: 1, translateX: "0%" }}
            exit={{ opacity: 0, scale: 0.9, translateY: 10, transition: { duration: 0.1 } }}
            style={{
              position: "absolute",
              top: position.top,
              left: position.left,
              transformOrigin: "top right",
              background: "white",
              borderRadius: "6px",
              boxSizing: "border-box",
              boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.25)",
              zIndex: 1000,
              padding: "8px",
            }}
          >
            {/* Arrow */}
            <div
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: "-6px",
                width: "0",
                height: "0",
                borderTop: "6px solid transparent",
                borderBottom: "6px solid transparent",
                borderLeft: "6px solid white",
              }}
            ></div>

            {/* Content */}
            {content}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MapperPopover;
