import React, { useState, useRef, ChangeEvent } from "react";
import { SSelect } from "@avalara/skylab-sdk/react";

const noop = () => {
  // no operation (do nothing real quick)
};

export const SkylabSelectWrapper = (props: any) => {
  return <FixRequiredSelect {...props} SelectComponent={SSelect} />;
};

interface FixRequiredSelectProps {
  value?: string;
  onChange: (value: string, actionMeta: any) => void;
  SelectComponent: React.ComponentType<any>;
  required: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  "input-id": string;
  className?: string;
}

const FixRequiredSelect: React.FC<FixRequiredSelectProps> = (props) => {
  const { SelectComponent, required, className, ...restProps } = props;
  const [value, setValue] = useState(props.value ?? "");
  const selectRef = useRef<HTMLDivElement>(null);
  const inputId = props["input-id"];

  const enableRequired = !props.isDisabled;

  const onChange = (valueChanged: string, actionMeta: any) => {
    props.onChange(valueChanged, actionMeta);
    setValue(valueChanged);
  };

  const getValue = () => {
    if (props.value !== undefined) return props.value;
    return value || "";
  };

  const scrollUlToLi = (ul: HTMLUListElement, li: HTMLLIElement) => {
    if (!ul || !li) return;
    ul.scrollTo({ top: 0 });
    const ulRect = ul.getBoundingClientRect();
    const liRect = li.getBoundingClientRect();
    const top = liRect.top - ulRect.top - 7;

    ul.scrollTo({ top: top });
  };

  const getLiList = (ul: HTMLUListElement): Promise<NodeListOf<HTMLLIElement>> => {
    return new Promise((resolve) => setTimeout(() => resolve(ul.querySelectorAll("li"))));
  };

  const fixSelectedItemFocusWhenOpen = async (e: any) => {
    const componentId = `[input-id=${e.detail.selectInputId}]`;

    const webComponent = document.querySelector("mapper-ui-component");
    const component = webComponent?.shadowRoot || document;
    const componentRoot = component.querySelector(componentId);

    if (!componentRoot) {
      console.error(`Component with ID ${componentId} not found.`);
      return;
    }

    const ul = componentRoot.querySelector("ul");
    if (!ul) {
      console.error("The <ul> element was not found in the component.");
      return;
    }

    let selectedChild = 1;
    const liList = await getLiList(ul);
    if (!liList.length) {
      console.warn("No <li> elements found in the list.");
      return;
    }

    liList.forEach((li, index) => {
      li.className = "";
      const liValue = li.getAttribute("data-value");
      if (liValue === props.value) {
        li.className = "visually-focused";
        selectedChild = index + 1;
      }
    });

    const liSelectedSelector = `li:nth-child(${selectedChild})`;
    const liSelected = ul.querySelector(liSelectedSelector) as HTMLLIElement;
    if (liSelected) {
      scrollUlToLi(ul, liSelected);
    } else {
      console.warn("Selected <li> element not found.");
    }
  };

  return (
    <div ref={selectRef} className={className}>
      <SelectComponent
        {...restProps}
        inputId={inputId}
        onChange={onChange}
        onS-open={fixSelectedItemFocusWhenOpen}
      />
      {enableRequired && (
        <input
          id={`${inputId}-hidden`}
          tabIndex={-1}
          aria-label="select column required"
          autoComplete="off"
          style={{
            opacity: 0,
            width: "100%",
            height: 0,
            position: "absolute",
            top: "28px",
          }}
          value={getValue()}
          onChange={(e: ChangeEvent<HTMLInputElement>) => noop()}
          onFocus={() => {
            (selectRef.current?.firstChild as HTMLDivElement).focus();
          }}
          required={required}
        />
      )}
    </div>
  );
};

FixRequiredSelect.defaultProps = {
  onChange: noop,
};

export default FixRequiredSelect;
