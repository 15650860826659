import { SAlert } from "@avalara/skylab-sdk/react";
import { MappingContext } from "../../../context/mappingDataContext";
import { IAnswerMapping } from "../../../domain/mappingTypes";
import { ReactNode } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";

const printTableResults = (item: IAnswerMapping): ReactNode => {
  return (
    <tr key={item.id}>
      <td>{item.from.columnName}</td>
      <td>{item.to}</td>
    </tr>
  );
};

const Review = () => {
  const { fromTo, dataComponent, isEditMode, setData } = MappingContext();

  return (
    <>
      <PageHeader headerTitle="Review and save" />
      <SAlert id="alertId" status="info" noDismiss>
        <div>We need these settings to process your transactions.</div>
      </SAlert>

      {!isEditMode && (
        <div style={{ marginTop: "20px", width: "50%" }}>
          <span className="required">Name your template:</span>
          <input
            aria-label="Name your template"
            required
            data-testid="input-new-schema-name"
            value={dataComponent.schemaName || ""}
            maxLength={80}
            onChange={(value) =>
              setData((d) => {
                return {
                  ...d,
                  schemaName: value.target.value,
                  outputSchemaName: `GT - ${value.target.value}`,
                };
              })
            }
            type="text"
            id="input-id-1"
          />
        </div>
      )}

      <h3>Required columns</h3>
      <table style={{ marginBottom: "40px" }}>
        <thead>
          <tr>
            <th style={{ width: "34%" }}>Avalara Columns </th>
            <th>Imported spreadsheet column</th>
          </tr>
        </thead>
        <tbody>
          {fromTo
            .filter((item) => !item.isOptionalColumn)
            .map((item) => {
              return printTableResults(item);
            })}
        </tbody>
      </table>

      <h3>Optional columns</h3>
      <table style={{ marginBottom: "40px" }}>
        <thead>
          <tr>
            <th style={{ width: "34%" }}>Avalara Columns </th>
            <th>Imported spreadsheet column</th>
          </tr>
        </thead>
        <tbody>
          {!fromTo.some((item) => item.isOptionalColumn) && (
            <tr>
              <td colSpan={2} className="text-center">
                There´s no option columns
              </td>
            </tr>
          )}
          {fromTo
            .filter((item) => item.isOptionalColumn)
            .map((item) => {
              return printTableResults(item);
            })}
        </tbody>
      </table>
    </>
  );
};

export default Review;
