import React from "react";
import { AnimatePresence, motion } from "framer-motion";

type Props = { showElement: boolean; id: string; duration?: number; children: React.ReactElement };

const ModalEntrance = (props: Props) => {
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };
  const duration = !props.duration ? 0.3 : props.duration;

  return (
    <AnimatePresence>
      {props.showElement && (
        <motion.div
          key={props.id}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={modalVariants}
          transition={{ duration }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalEntrance;
