import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { debounce } from "lodash";

interface ISlideDownEffect {
  children: React.ReactElement;
  showElement: boolean;
  id: string;
  initialAnimations?: boolean;
}

const SlideDownEffect: React.FunctionComponent<ISlideDownEffect> = (props) => {
  const initialAnimations = props.initialAnimations ?? false;

  const [isReady, setIsReady] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const debouncedShowElement = debounce(() => {
      setIsReady(true);
    }, 20);

    if (props.showElement) {
      debouncedShowElement();
    } else {
      setIsReady(false);
      debouncedShowElement.cancel();
    }

    return () => {
      debouncedShowElement.cancel();
    };
  }, [props.showElement]);

  return (
    <AnimatePresence initial={initialAnimations}>
      {props.showElement && isReady && (
        <motion.div
          key={props.id}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div ref={contentRef}>{props.children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SlideDownEffect;
