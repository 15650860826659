import React, { useEffect, useState } from "react";

import { SAlert, SSelect } from "@avalara/skylab-sdk/react";

import { getSchemasByTenantId } from "../../services/schemaService";
import { sendFile } from "../../services/fileService";

import FadeInEffect from "../../ui/FadeInEffect/FadeInEffect";
import FileUpload from "../../components/FileUpload/file-upload.component";
import XlsDialog from "../../components/XlsDialog/XlsDialog";
import CsvDialog from "../../components/CsvDialog/CsvDialog";

import { ISchema, IHeaders } from "../../domain/mappingTypes";
import { MappingContext } from "../../context/mappingDataContext";
import { isXls, readXlsFile } from "../../utils/spreadsheet";

import "./ImportFiles.scss";
import LoadingMask from "../../ui/LoadingMask/LoadingMask";
import SlideDownEffect from "../../ui/SlideDownEffect/SlideDownEffect";
import PageHeader from "../../components/PageHeader/PageHeader";
import { formatDate, formatFileSize, getFileExtension, hasDuplicates } from "../../utils/string";
import { checkDataCompleteness, generateCsvErrorMessage, readCsv } from "../../utils/csv";
import { useNavigate } from "react-router-dom";

type TSelectSchema = ISchema & {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
};

const responseSize = 100;
const AvaVATFullTemplateId = "avalara vat reporting generic template";
const AvaVATGLTemplateId = "avalara vat reporting gl template";
const AvaVATClearingTemplateId = "avalara vat Reporting clearing template";

const defaultSchemaConfig = {
  [AvaVATFullTemplateId]: {
    allowedExtensions: [
      "extratxt",
      "gentxt",
      "intratxt",
      "json",
      "multitxt",
      "txt",
      "xml",
      "xls",
      "xlsx",
    ],
    fileSizeLimit: 150,
  },
  [AvaVATGLTemplateId]: {
    allowedExtensions: [
      "extratxt",
      "gentxt",
      "intratxt",
      "json",
      "multitxt",
      "txt",
      "xml",
      "xls",
      "xlsx",
    ],
    fileSizeLimit: 150,
  },
  [AvaVATClearingTemplateId]: {
    allowedExtensions: [
      "clearingtxt",
      "txt",
      "xml",
      "xls",
      "xlsx"
    ],
    fileSizeLimit: 150,
  },
};

const defaultSchemas = [
  {
    schemaId: "",
    schemaName: "",
    schemaExtension: "",
    label: "Select...",
    value: "",
  },
  {
    schemaId: AvaVATClearingTemplateId,
    schemaName: "Avalara VAT Reporting Clearing Template",
    schemaExtension: "",
    label: "Avalara VAT Reporting Clearing Template",
    value: AvaVATClearingTemplateId,
  },
  {
    schemaId: AvaVATFullTemplateId,
    schemaName: "Avalara VAT Reporting Full Template",
    schemaExtension: "",
    label: "Avalara VAT Reporting Full Template",
    value: AvaVATFullTemplateId,
  },
  {
    schemaId: AvaVATGLTemplateId,
    schemaName: "Avalara VAT Reporting GL Template",
    schemaExtension: "",
    label: "Avalara VAT Reporting GL Template",
    value: AvaVATGLTemplateId,
  },
];

const parseFileInfo = (file: File) => {
  return {
    name: file.name,
    size: formatFileSize(file.size),
    type: getFileExtension(file.name),
    lastModified: formatDate(new Date(file.lastModified).toISOString()),
  };
};

function ImportFiles() {
  const {
    dataComponent,
    isEditMode,
    disableCountryStep,
    customColumns,
    fromData,
    fromTo,
    fromToHistory,
    updateFromTo,
    setFromData,
    setData,
  } = MappingContext();

  const [savedTemplate, setSavedTemplate] = useState("none");
  const [isProcessingUpload, setIsProcessingUpload] = useState(false);
  const [isLoadingSchemas, setIsLoadingSchemas] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(
    "Upload error, check if the file is not corrupted"
  );
  const [xlsHasError, setXlsHasError] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [worksheetNames, setWorksheetNames] = useState<string[]>([]);
  const [csvHasError, setCsvHasError] = useState(false);
  const [internetConnectionError, setInternetConnectionError] = useState(false);
  const [totalSchemas, setTotalSchemas] = useState(0);
  const [isAsyncSelect, setIsAsyncSelect] = useState(false);
  const [searchSelectValue, setSearchSelectValue] = useState("");

  const [file, setFile] = useState(new File([], ""));
  const [readHeaders, setReadHeaders] = useState<Partial<IHeaders>[]>([]);
  const [showDialogCsv, setShowDialogCsv] = useState(false);
  const [showDialogXls, setShowDialogXls] = useState(false);
  const [showConfirmHeadersCsv, setShowConfirmHeadersCsv] = useState(false);
  const [showConfirmHeadersXls, setShowConfirmHeadersXls] = useState(false);
  const [allowedExtensions, setAllowedExtensions] = useState<string[]>([]);
  const [schemas, setSchemas] = useState([
    { ...defaultSchemas[0], selected: true } as TSelectSchema,
  ]);
  const [fileSizeLimit, setFileSizeLimit] = useState(100);
  const [selectedSchema, setSelectedSchema] = useState({ schemaId: "" } as TSelectSchema);
  const [savedTemplateName, setSavedTemplateName] = useState(
    sessionStorage.getItem("saveSuccess") ?? ""
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (
      disableCountryStep &&
      customColumns.length > 0 &&
      (fromData.length === 0 || (isEditMode && fromTo.length === 0))
    ) {
      setFromData(customColumns);
      updateFromTo(customColumns, fromTo, fromToHistory);
    }
  }, [
    customColumns,
    fromTo,
    fromData,
    fromToHistory,
    isEditMode,
    disableCountryStep,
    updateFromTo,
    setFromData,
  ]);

  useEffect(() => {
    if (savedTemplateName) {
      const timer = setTimeout(() => {
        setSavedTemplateName("");
      }, 5000);

      return () => clearTimeout(timer);
    }
    sessionStorage.removeItem("saveSuccess");
  }, [savedTemplateName]);

  useEffect(() => {
    if (dataComponent.featureFlag === "true") setSavedTemplate("yes");
    if (isEditMode) return;

    const initSchemasSelect = async () => {
      try {
        setIsLoadingSchemas(true);
        const response = await getSchemasByTenantId(dataComponent.tenantId);
        const selectOptionsSchema = getSchemaOptions(response, defaultSchemas);
        setSchemas(selectOptionsSchema);
        setTotalSchemas(response.count);
      } catch (error) {
        setInternetConnectionError(true);
      } finally {
        setIsLoadingSchemas(false);
        setTimeout(() => setIsAsyncSelect(true), 300);
      }
    };

    initSchemasSelect();
  }, [dataComponent.tenantId, dataComponent.featureFlag, isEditMode]);

  useEffect(() => {
    if (isEditMode) {
      setSavedTemplate("no");
      updateValidExtensions("");
    }
  }, [isEditMode]);

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearchSchemas(searchSelectValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
    //eslint-disable-next-line
  }, [searchSelectValue]);

  const getSchemaOptions = (response: any, existingSchemas: Partial<TSelectSchema>[]) => {
    const newSchemas = response.values.map((item: any) => ({
      ...item,
      label: item.schemaName,
      value: item.schemaId,
      selected: false,
    }));
    return [...existingSchemas, ...newSchemas];
  };

  const getData = async (tenantId: string, search?: string) => {
    try {
      setIsLoadingSearch(true);
      const response = await getSchemasByTenantId(tenantId, search);
      const selectOptionsSchema = getSchemaOptions(response, search ? schemas : defaultSchemas);
      setSchemas(selectOptionsSchema);
    } catch (error) {
      setInternetConnectionError(true);
    } finally {
      setIsLoadingSearch(false);
    }
  };

  const hideAllMessages = () => {
    setUploadSuccess(false);
    setUploadError(false);
    setXlsHasError(false);
    setCsvHasError(false);
    setSavedTemplateName("");
    setUploadErrorMessage("Upload error, check if the file is not corrupted");
  };

  const handleOptionChange = (option: string) => {
    setSavedTemplate(option);
    hideAllMessages();
    handleSchemaChange({ item: { schemaId: "" } });
    setData((d) => ({ ...d, headerRowPosition: 1 }));

    const schemaId = option === "yes" ? dataComponent.schemaId || "" : "";
    updateValidExtensions(schemaId);

    const event = new Event("savedTemplateOptionChanged");
    window.dispatchEvent(event);
  };

  const handleReadXls = async (fileToRead: File) => {
    try {
      const headerRowInit = dataComponent.headerRowPosition - 1;
      const { workbookSheetNames, headerListNames, headerListObjects, message } = await readXlsFile(
        fileToRead,
        headerRowInit
      );

      setWorksheetNames(workbookSheetNames);
      setReadHeaders(headerListObjects);
      hideAllMessages();

      if (workbookSheetNames.length === 1) {
        setShowConfirmHeadersXls(true);
      }

      if (hasDuplicates(headerListNames) || headerListNames.length === 0) {
        setXlsHasError(true);
        setUploadErrorMessage(message);
      }

      setTimeout(() => {
        setShowDialogXls(true);
      }, 300);
    } catch (error) {
      console.error("Error processing XLSX:", error);
      hideAllMessages();
      setXlsHasError(true);
      setShowConfirmHeadersXls(true);
      setUploadErrorMessage("Error processing XLSX");
      setTimeout(() => {
        setShowDialogXls(true);
      }, 300);
    } finally {
      setIsProcessingUpload(false);
    }
  };

  const handleReadCsv = async (file: File) => {
    try {
      const { foundHeaders, headerList, dataCSV } = await readCsv(
        file,
        dataComponent.headerRowPosition,
        dataComponent.schemaSeparator
      );

      hideAllMessages();
      setReadHeaders(headerList);
      validateCsvData(foundHeaders, dataCSV);
      setShowConfirmHeadersCsv(true);
    } catch (error) {
      console.error("Error processing CSV:", error);
      hideAllMessages();
      setCsvHasError(true);
    } finally {
      setIsProcessingUpload(false);
    }
  };

  const validateCsvData = (foundHeaders: string[], dataCSV: string[][]) => {
    const hasDuplicated = hasDuplicates(foundHeaders);
    const isInvalidHeaderSize = foundHeaders.length <= 1;
    const isInvalidDataCompleteness = !checkDataCompleteness(foundHeaders, dataCSV);
    const csvHasError = hasDuplicated || isInvalidHeaderSize || isInvalidDataCompleteness;

    if (csvHasError) {
      setCsvHasError(true);
      const message = generateCsvErrorMessage(
        hasDuplicated,
        isInvalidHeaderSize,
        isInvalidDataCompleteness
      );
      setUploadErrorMessage(message);
    }
  };

  const uploadSavedTemplate = async (files: any) => {
    const result = await sendFile(
      files[0],
      selectedSchema.schemaId in defaultSchemaConfig
        ? selectedSchema.schemaId
        : selectedSchema.schemaName,
      dataComponent.enableVatPlatformProcessing === "true"
    );

    if (!result.error) {
      setIsProcessingUpload(false);
      setUploadSuccess(true);
      setUploadError(false);
    } else {
      setIsProcessingUpload(false);
      setUploadError(true);
      setUploadSuccess(false);
      if (result?.status === 409) {
        setUploadErrorMessage("This file has already been uploaded.");
      }
    }
  };

  const configureNewTemplate = (files: any) => {
    const file = parseFileInfo(files[0]);
    try {
      setData((d) => {
        return { ...d, file };
      });

      if (isXls(files[0])) {
        handleReadXls(files[0]);
        setShowConfirmHeadersXls(false);
        setData((d) => {
          return { ...d, schemaSeparator: ";" };
        });
      } else {
        setUploadError(false);
        setTimeout(() => setShowDialogCsv(true), 300);
        setFile(files[0]);
      }
    } catch (error) {
      hideAllMessages();
      setUploadError(true);
      setIsProcessingUpload(false);
    }
  };

  const updateUploadedFiles = async (files: any) => {
    if (!files || files.length === 0) return;

    hideAllMessages();
    setIsProcessingUpload(true);
    setReadHeaders([]);

    if (savedTemplate === "yes") {
      uploadSavedTemplate(files);
    } else {
      configureNewTemplate(files);
    }
  };

  const handleSchemaChange = (selectedObject: any) => {
    const selectedSchemaObj = selectedObject.item;
    const newSchemaList = schemas.map((item) => {
      item.selected = item.schemaId === selectedSchemaObj.schemaId;
      return item;
    });

    setData((d) => {
      return { ...d, schemaId: selectedSchemaObj.schemaId };
    });

    setSchemas(newSchemaList);
    updateValidExtensions(selectedSchemaObj.schemaId);
    setSelectedSchema(selectedSchemaObj);
  };

  const updateValidExtensions = (schema: string) => {
    if (schema in defaultSchemaConfig) {
      const { allowedExtensions, fileSizeLimit } =
        defaultSchemaConfig[schema as keyof typeof defaultSchemaConfig];
      setAllowedExtensions(allowedExtensions);
      setFileSizeLimit(fileSizeLimit);
    } else {
      setAllowedExtensions(["csv", "txt", "xls", "xlsx"]);
      setFileSizeLimit(100);
    }
  };

  const handleSearchSchemas = (search: string) => {
    const shemasFiltered = schemas.filter((schema) =>
      schema.schemaName.toLowerCase().includes(search.toLowerCase().trim())
    );
    if (!shemasFiltered.length && totalSchemas > responseSize)
      getData(dataComponent.tenantId, search);
  };

  const closeXlsDialogHandler = () => {
    setShowDialogXls(false);
    setShowConfirmHeadersXls(false);
    setIsProcessingUpload(false);
  };

  const canUpload = (savedTemplate === "yes" && dataComponent.schemaId) || savedTemplate === "no";

  return (
    <>
      <SlideDownEffect showElement={internetConnectionError} id="error-conection">
        <SAlert status="error" noDismiss>
          <div data-testid="user-message-upload-error">
            An unexpected error occurred, please check your internet conection
          </div>
        </SAlert>
      </SlideDownEffect>

      <div className="import-files-component">
        <PageHeader headerTitle="Import files" />

        {!isEditMode && (
          <>
            <p className="sub-title">
              Please answer the following questions to help ensure we map your data and create your
              template correctly.
            </p>

            <fieldset className="radio-group">
              <p>Does this file match an Avalara template or existing data mapping?</p>
              <input
                type="radio"
                name="saved"
                value="yes"
                id="saved-yes"
                data-testid="radio-saved-yes"
                checked={savedTemplate === "yes"}
                onChange={() => {
                  handleOptionChange("yes");
                }}
              />
              <label className="radio-label" htmlFor="saved-yes">
                Yes
              </label>

              <input
                type="radio"
                name="saved"
                value="no"
                id="saved-no"
                data-testid="radio-saved-no"
                checked={savedTemplate === "no"}
                disabled={dataComponent.featureFlag === "true"}
                onChange={() => handleOptionChange("no")}
              />
              <label className="radio-label" htmlFor="saved-no">
                No
              </label>
            </fieldset>
          </>
        )}

        {savedTemplate === "no" && (
          <>
            <p>Select header row:</p>
            <input
              id="header-row-position"
              className="template-select"
              type="number"
              aria-label="header-row-position"
              min={1}
              value={dataComponent.headerRowPosition || 1}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                setData((d) => {
                  const value = e.target.value ? parseInt(e.target.value) : 1;
                  return { ...d, headerRowPosition: value };
                });
              }}
            />
          </>
        )}

        {savedTemplate === "yes" && (
          <>
            <p>Which template would you like to use?</p>
            <div style={{ display: "inline-block" }}>
              {isLoadingSchemas && <LoadingMask smallSpinner={true} />}
              <SSelect
                className="template-select"
                inputId="dropdown-template-name"
                data-testid="dropdown-template-name"
                multiple={false}
                async={isAsyncSelect}
                loading={isLoadingSearch}
                disabled={schemas === undefined}
                showSelectionCount={false}
                optionsList={schemas}
                onS-input={(e) => setSearchSelectValue(e.detail.inputValue)}
                onS-select={(e) => handleSchemaChange(e.detail)}
              />
            </div>
          </>
        )}

        <div className="uploader-container">
          <FileUpload
            disabled={!canUpload}
            isProcessing={isProcessingUpload}
            updateFilesCb={updateUploadedFiles}
            allowedExtensions={allowedExtensions}
            fileSizeLimit={fileSizeLimit}
          />
        </div>

        {uploadError && (
          <div className="alert-fix-position">
            <SAlert status="error" onS-dismiss={(e) => setUploadError(false)}>
              <p data-testid="user-message-upload-error">{uploadErrorMessage}</p>
            </SAlert>
          </div>
        )}

        {uploadSuccess && (
          <SAlert
            className="alert-fix-position"
            status="success"
            onS-dismiss={(e) => setUploadSuccess(false)}
          >
            <p data-testid="user-message-upload-success">File uploaded successfully.</p>
          </SAlert>
        )}

        <FadeInEffect showElement={!!savedTemplateName} id="sucessSavedMessage">
          <SAlert
            className="alert-fix-position"
            status="success"
            onS-dismiss={(e) => setSavedTemplateName("")}
          >
            <p data-testid="user-message-schema-save-success">
              Template {savedTemplateName} created successfully.
            </p>
          </SAlert>
        </FadeInEffect>

        <hr style={{ marginTop: "60px" }} />
      </div>

      <FadeInEffect id="dialogId" showElement={showDialogCsv} duration={0.3}>
        <CsvDialog
          file={file}
          showDialog={showDialogCsv}
          showConfirmHeadersCsv={showConfirmHeadersCsv}
          csvHasError={csvHasError}
          uploadErrorMessage={uploadErrorMessage}
          readHeaders={readHeaders}
          readCsv={handleReadCsv}
          setShowDialog={setShowDialogCsv}
          setIsProcessingUpload={setIsProcessingUpload}
          setShowConfirmHeadersCsv={setShowConfirmHeadersCsv}
        />
      </FadeInEffect>

      <FadeInEffect id="confirmHeaders" showElement={showDialogXls} duration={0.3}>
        <XlsDialog
          showDialog={showDialogXls}
          showConfirmHeadersXls={showConfirmHeadersXls}
          setShowConfirmHeadersXls={setShowConfirmHeadersXls}
          worksheetNames={worksheetNames}
          readHeaders={readHeaders}
          xlsHasError={xlsHasError}
          uploadErrorMessage={uploadErrorMessage}
          onCloseXlsDialog={closeXlsDialogHandler}
        />
      </FadeInEffect>

      {isEditMode && (
        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <button className="primary" onClick={() => navigate("/mapping")}>
            Skip import file
          </button>
        </div>
      )}
    </>
  );
}

export default ImportFiles;
