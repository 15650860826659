import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { debounce } from "lodash";

interface IScaleEffect {
  children: React.ReactElement;
  showElement: boolean;
  id: string;
  initialWidth?: number;
  delay?: number;
}

const ScaleEffect: React.FunctionComponent<IScaleEffect> = (props) => {
  const _initialWidth = !props.initialWidth ? 0 : props.initialWidth;
  const _delay = !props.delay ? 0 : props.delay;

  const [isReady, setIsReady] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const debouncedShowElement = debounce(() => {
      setIsReady(true);
    }, 10);

    if (props.showElement) {
      debouncedShowElement();
    } else {
      setIsReady(false);
      debouncedShowElement.cancel();
    }

    return () => {
      debouncedShowElement.cancel();
    };
  }, [props.showElement]);

  return (
    <AnimatePresence>
      {props.showElement && isReady && (
        <motion.div
          key={props.id}
          initial={{ opacity: 0, height: 0, width: _initialWidth }}
          animate={{ opacity: 1, height: "auto", width: "auto" }}
          exit={{ opacity: 0, height: 0, width: _initialWidth }}
          transition={{ delay: _delay }}
        >
          <div ref={contentRef}>{props.children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScaleEffect;
