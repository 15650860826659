import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { SCol, SRow } from "@avalara/skylab-sdk/react";
import { OptionsData } from "@avalara/skylab-sdk";
import { IAnswerMapping, IHeaders, ITransformation } from "../../domain/mappingTypes";
import uuid from "react-uuid";
import { MappingContext } from "../../context/mappingDataContext";
import TransformationComponent from "../TransformationComponent";

import "./styles.scss";
import { AnimatePresence, motion } from "framer-motion";
import SlideDownEffect from "../../ui/SlideDownEffect/SlideDownEffect";
import ValidationErrors from "./ValidationErrors";
import SkylabSelectOrInputText from "../TransformationComponent/SkylabSelectOrInputText";
import MapperPopover from "../../ui/MapperPopover/MapperPopover";

type IProps = {
  item: IAnswerMapping;
  data: Array<IHeaders>;
};

const collpaseVariants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 },
};

const FromToComponent = ({ item, data }: IProps) => {
  const { setFromTo, fromTo } = MappingContext();

  const [isOptional, setIsOptional] = useState(item.isOptionalColumn);
  const [selectShemaOption, setSelectShemaOption] = useState<OptionsData[]>([]);
  const isDateFromJson = item.from.dataType.toLowerCase() === "date";
  const [validationError, setValidationError] = useState<string[]>([]);
  const [inputColumnName, setInputColumnName] = useState<string>(
    item.isCustomColumn ? item.to : ""
  );
  const [changeSelectToInput, setChangeSelectToInput] = useState(item.isCustomColumn);
  const inputColumnNameRef = useRef<HTMLInputElement>(null);
  const [enableInitialAnimation, setEnableInitialAnimation] = useState(false);

  const onClickTo = (field: string, value: any) => {
    let newItem = { ...item };
    if (field === "to" && !value) newItem = getResetedItem();
    if (field === "hasTransformation" && !value) newItem = getResetedItem(false);

    const valueToAdd = {
      ...newItem,
      [field]: value,
    } as IAnswerMapping;

    updateItens(valueToAdd);
  };

  const getResetedItem = (removeTo: boolean = true) => {
    const resetedItem: Partial<IAnswerMapping> = { ...item };
    if (removeTo) delete resetedItem.to;
    delete resetedItem.transformations;
    delete resetedItem.isCustomColumn;
    resetedItem.hasTransformation = false;
    return resetedItem as IAnswerMapping;
  };

  const setResetedItem = (value: boolean) => {
    const valueToAdd = {
      ...getResetedItem(),
      isOptionalColumn: value,
    } as IAnswerMapping;

    updateItens(valueToAdd);
  };

  useLayoutEffect(() => {
    const shemaOptions = data.map((header) => {
      const newList: OptionsData = {
        label: header.name,
        value: header.name,
        selected: header.name === item.to,
      };
      return newList;
    });
    shemaOptions.unshift({
      label: "Select...",
      value: "",
    });
    setSelectShemaOption(shemaOptions);
  }, [data, item.to]);

  useEffect(() => {
    setTimeout(() => {
      setEnableInitialAnimation(true);
    }, 500);
  }, []);

  const onOptionalChange = (value: boolean) => {
    setIsOptional(value);
    setResetedItem(value);
    setChangeSelectToInput(false);
    setInputColumnName("");
    setValidationError([]);
  };

  const updateItens = (valueToAdd: any) => {
    const itensToUpdate = fromTo.map((i) => {
      if (i.id === item.id) return valueToAdd;
      else return i;
    });
    setFromTo(itensToUpdate);
  };

  const addTransformation = () => {
    const newTransformation = {
      id: uuid(),
      hasTransformation: item.hasTransformation ?? false,
      concatenation: {
        selectOptionList: [selectShemaOption, selectShemaOption],
        values: ["", ""],
        isInput: [false, false],
      },
      replaceType: "byValue",
      flipSignageType: "flipSignage",
    } as ITransformation;

    updateItens({
      ...item,
      transformations: item.transformations
        ? [...item.transformations, newTransformation]
        : [newTransformation],
    } as IAnswerMapping);
  };

  const removeTransformation = (id: string) => {
    updateItens({
      ...item,
      transformations: [...item.transformations.filter((x) => x.id !== id)],
    } as IAnswerMapping);
  };

  const showTransformation = item.hasTransformation && !item.isDate;
  const hasMultipleTransformationsWithConditions =
    item.transformations === undefined || item.transformations?.length === 0
      ? true
      : item.transformations?.length === 1 ||
        (item.transformations?.length > 1 &&
          item.transformations?.every((transformation) => transformation.conditions?.length > 0));

  const transformationRequired =
    item?.to &&
    item.hasTransformation &&
    (!item.transformations || item.transformations?.length === 0);

  let exemploData = [] as any;
  if (item.to) {
    const values = data.filter((d) => d.name === item.to)[0]?.data;
    exemploData = values !== undefined && values?.length > 0 ? values : ["No Data"];
  }

  const checkDuplicatedColumn = (value: string) => {
    const hasInputColumn = fromTo.some((x) => x.to === value);
    const hasSelectColumn = selectShemaOption.some((x) => x.label === value);
    return hasSelectColumn || hasInputColumn;
  };

  const validateInputColumnName = (value: string) => {
    const errorSet = new Set<string>();
    if (/^\d/.test(value)) {
      errorSet.add("numberBeginning");
    } else {
      errorSet.delete("numberBeginning");
    }
    if (/[^a-zA-Z0-9 _]/.test(value)) {
      errorSet.add("specialCharacters");
    } else {
      errorSet.delete("specialCharacters");
    }
    if (/\s/.test(value)) {
      errorSet.add("spaces");
    } else {
      errorSet.delete("spaces");
    }
    if (checkDuplicatedColumn(value)) {
      errorSet.add("duplicateName");
    } else {
      errorSet.delete("duplicateName");
    }
    return Array.from(errorSet);
  };

  const onChangeInputColumnName = (value: string) => {
    const errorsList = validateInputColumnName(value);
    setValidationError(errorsList);
    setInputColumnName(value);

    const updatedItem = { ...item, to: value };
    updateItens(updatedItem);
  };

  const onBlurInputColumnName = () => {
    if (validationError.length > 0) {
      setInputColumnName("");
      setValidationError([]);
      inputColumnNameRef.current?.focus();
      const updatedItem = { ...item, to: "" };
      updateItens(updatedItem);
    }
  };

  const onTrasnformSelectInput = (isInput: boolean) => {
    if (isOptional) return;
    setChangeSelectToInput(isInput);
    setInputColumnName("");
    setValidationError([]);
    const _newItem = getResetedItem();
    if (isInput) {
      updateItens({ ..._newItem, hasTransformation: true, isCustomColumn: true });
      setTimeout(() => {
        inputColumnNameRef.current?.focus();
      }, 100);
    } else {
      updateItens({ ..._newItem });
    }
  };

  return (
    <>
      <SRow className="from-to-component">
        <SCol span="6" className="pad-bottom-sm">
          <SRow show="sm" hide="md lg xl">
            <SCol className="pad-all-none">
              <h2 className="heading-sm">Avalara - Destination Field</h2>
            </SCol>
          </SRow>

          <div className="wapper_items">
            <span className="item-name">{item.from.columnName}</span>
          </div>
          <p className="item-metadata">{item.from.columnDescription}</p>

          <div className="container-helper">
            <div className="header">Expected format</div>
            <div className="header">Sample data</div>
          </div>

          <div className="container-helper">
            <div className="box display-linebreak">
              <div className="content">{item.from.expectedFormat.replaceAll("\n\n", "\n")}</div>
            </div>
            <div
              className={`box display-linebreak ${
                item.from.expectedFormat || item.from.sampleData ? "divider-left" : ""
              }`}
            >
              <div className="content text-center">
                {item.from.sampleData.replaceAll("\n\n", "\n")}
              </div>
            </div>
          </div>

          <div className="optional-checkbox-container">
            <input
              disabled={item.from.mandatory}
              type="checkbox"
              id={item.from.columnName + "optional-checkbox"}
              name={item.from.columnName + "optional-checkbox"}
              value={"true"}
              checked={isOptional}
              onChange={(e) => onOptionalChange(e.target.checked)}
            />
            <label htmlFor={item.from.columnName + "optional-checkbox"}>Skip</label>
          </div>
        </SCol>
        <SCol span="1" className="pad-bottom-sm" />
        <SCol span="5" className="pad-bottom-sm">
          <SRow show="sm" hide="sm md lg xl">
            <SCol className="pad-all-none">
              <h2 className="heading-sm">Imported File - Source Field</h2>
            </SCol>
          </SRow>
          <p className="select-header">Select column heading from your source file:</p>
          {(item.from.mandatory || !isOptional) && (
            <p style={{ fontStyle: "italic", marginTop: "3px" }}>
              This is a <span className="mandatory-highlight">mandatory</span> field
            </p>
          )}
          {!item.from.mandatory && isOptional && (
            <p style={{ fontStyle: "italic", marginTop: "3px" }}>
              This is an <span className="optional-highlight">optional</span> field
            </p>
          )}
          <div style={{ position: "relative", height: "36px" }}>
            <SkylabSelectOrInputText
              item={item}
              isOptional={!item.from.mandatory && isOptional}
              changeSelectToInput={changeSelectToInput}
              showSwitchButton={!isDateFromJson}
              selectShemaOption={selectShemaOption}
              inputRef={inputColumnNameRef}
              inputValue={inputColumnName}
              selectValue={item.to}
              onChangeSelect={(value) => {
                onClickTo("to", value);
              }}
              onTrasnformSelectInput={onTrasnformSelectInput}
              onBlurInputColumnName={onBlurInputColumnName}
              onChangeInputColumnName={onChangeInputColumnName}
            />
          </div>

          <AnimatePresence>
            {changeSelectToInput && (
              <div className="container-info-instractions">
                <SlideDownEffect
                  initialAnimations={enableInitialAnimation}
                  showElement={changeSelectToInput}
                  id="instructions"
                >
                  <>
                    <div>The column name must follow all the rules below:</div>
                    <ValidationErrors validationError={validationError} />
                  </>
                </SlideDownEffect>
              </div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            <div
              style={{
                display:
                  item.to == null || item.to === "" || item.isCustomColumn ? "none" : "block",
              }}
            >
              <MapperPopover
                trigger={
                  <p
                    style={{
                      fontStyle: "italic",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Show Input Data Sample
                  </p>
                }
                content={
                  <table className="table_popover">
                    <thead>
                      <tr>
                        <th className="table_popover_th">
                          <p>{item.to}</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {exemploData?.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <p>{item}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              />

              {isDateFromJson ? (
                <motion.div
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={collpaseVariants}
                  transition={{ duration: 0.4 }}
                >
                  <br />
                  <fieldset>
                    <label htmlFor={`selectData-column-${item.from.columnName}`}>
                      Please confirm the inbound date format. <br /> Conversion to the desired
                      format will be take place automatically.
                    </label>
                    <select
                      data-testid={`selectData-column-${item.from.columnName}`}
                      id={`selectData-column-${item.from.columnName}`}
                      required={item.to !== "" && item.to !== undefined}
                      value={item.dateFormat || ""}
                      onChange={(e) => onClickTo("dateFormat", e.target.value)}
                    >
                      <option value="">Select...</option>
                      <option value="MM/dd/yyyy">MM/dd/yyyy</option>
                      <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                      <option value="dd/M/yyyy">dd/M/yyyy</option>
                      <option value="dd-MM-yyyy">dd-MM-yyyy</option>
                      <option value="dd-M-yyyy">dd-M-yyyy</option>
                      <option value="yyyy-MM-dd">yyyy-MM-dd</option>
                      <option value="yyyyMMdd">yyyyMMdd</option>
                      <option value="M/d/yyyy">M/d/yyyy</option>
                    </select>
                  </fieldset>
                </motion.div>
              ) : (
                <motion.div
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={collpaseVariants}
                  transition={{ duration: 0.4 }}
                >
                  <br />
                  <p className="select-header">
                    Does the data in the source file match the Avalara data formatting rules?
                  </p>
                  <fieldset className="container_radio" aria-labelledby="label2">
                    <input
                      type="radio"
                      data-testid={"radio-match-formating-yes-" + item.from.columnName}
                      id={item.id + "yesId"}
                      name={item.id + "yesId"}
                      checked={!item.hasTransformation || false}
                      onChange={() => {
                        onClickTo("hasTransformation", false);
                      }}
                    />
                    <label htmlFor={item.id + "yesId"}>Yes</label>
                    <input
                      type="radio"
                      data-testid={"radio-match-formating-no-" + item.from.columnName}
                      id={item.id + "noId"}
                      name={item.id + "noId"}
                      checked={item.hasTransformation || false}
                      onChange={() => {
                        onClickTo("hasTransformation", true);
                      }}
                    />
                    <label htmlFor={item.id + "noId"}>No</label>
                  </fieldset>
                </motion.div>
              )}
            </div>
          </AnimatePresence>
        </SCol>
      </SRow>

      <AnimatePresence>
        {showTransformation && (
          <motion.div
            key="tranformation-block"
            initial={false}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AnimatePresence>
              {item.transformations?.map((t) => {
                return (
                  <motion.div
                    key={t.id}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <TransformationComponent
                      data-testid={item.id}
                      key={t.id}
                      item={item}
                      transformationData={t}
                      deleteClick={() => removeTransformation(t.id)}
                    />
                  </motion.div>
                );
              })}
            </AnimatePresence>

            <div style={{ position: "relative", height: "40px" }}>
              <motion.div
                key="button-transformation"
                initial={{ overflow: "hidden", opacity: 0, height: 0 }}
                animate={{ overflow: "visible", opacity: 1, height: "auto" }}
                exit={{ overflow: "hidden", opacity: 0, height: 0 }}
                style={{ overflow: "hidden", display: "inline-block", position: "absolute" }}
                transition={{ duration: 0.2 }}
              >
                <button
                  disabled={!hasMultipleTransformationsWithConditions}
                  className="primary"
                  type="button"
                  data-testid="add_transformation_id"
                  onClick={addTransformation}
                >
                  + Transformation
                </button>
                {transformationRequired && (
                  <input
                    aria-label="required at least one transformation"
                    className="input-validation-hidden"
                    type="text"
                    required
                    onInvalid={(e) =>
                      e.currentTarget.setCustomValidity("Add at least a transformation")
                    }
                  />
                )}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default FromToComponent;
