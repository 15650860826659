import React from "react";
import { IAnswerMapping, ITransformation } from "../../domain/mappingTypes";
import { AnimatePresence, motion } from "framer-motion";
import { OptionsData } from "@avalara/skylab-sdk";
import { variantsSlideDown } from ".";
import { SkylabSelectWrapper } from "../../ui/FixSelectRequired/FixSelectRequired";

type Props = {
  transformation: ITransformation;
  item: IAnswerMapping;
  selectColumnOption: OptionsData[];
  setTransformation: React.Dispatch<React.SetStateAction<ITransformation>>;
};

const ByCopyComponent = (props: Props) => {
  const isCopy = props.transformation.replaceType === "byCopy";
  const activeZIndex = isCopy ? 3 : -1;
  const nameSkylabSelect = `dropdown-column-name-${props.transformation.id}-${props.item.from.columnName}`;
  const schemaSelectOptions = props.selectColumnOption.map((column) => {
    return { ...column, selected: column.value === props.transformation.value };
  });

  return (
    <AnimatePresence initial={false}>
      <motion.div
        style={{ zIndex: activeZIndex }}
        initial={false}
        animate={{
          width: isCopy ? 165 : 0,
          opacity: isCopy ? 1 : 0,
          height: isCopy ? "auto" : 0,
        }}
        transition={{ duration: 0.3 }}
      >
        <AnimatePresence initial={false}>
          return (
          <motion.div
            key="bCopy"
            style={{ position: "relative" }}
            variants={variantsSlideDown}
            initial="collapsed"
            animate="open"
            exit="collapsed"
          >
            <div style={{ width: "190px", height: "50px" }}>
              <label htmlFor={nameSkylabSelect} className="required">
                Column:
              </label>
              <SkylabSelectWrapper
                name={nameSkylabSelect}
                className="skylab-select-container"
                input-id={nameSkylabSelect}
                multiple={false}
                disabled={!isCopy}
                showSelectionCount={false}
                required={isCopy}
                data-testid={nameSkylabSelect}
                value={props.transformation.value || ""}
                onS-select={(e: any) =>
                  props.setTransformation((previousTransformation) => {
                    return { ...previousTransformation, value: e.detail.item.value };
                  })
                }
                optionsList={schemaSelectOptions}
              />
            </div>
          </motion.div>
          );
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
};

export default ByCopyComponent;
