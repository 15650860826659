import React, { useRef } from "react";

import { SAlert, SDialog } from "@avalara/skylab-sdk/react";
import { MappingContext } from "../../context/mappingDataContext";
import { IHeaders, Snapshot } from "../../domain/mappingTypes";
import { OverlapEffect, useContentOverlap } from "../../hooks/useOverlapEffect";

import "./SchemaEditedInfoDialog.scss";

interface ISchemaEditedInfoDialogProps {
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ComparedHeaderListProps {
  columns: string[] | IHeaders[];
  listToCompare: string[];
  getClassForColumn: (columnName: string, listToCompare: string[]) => string;
}

const ComparedHeaderList: React.FC<ComparedHeaderListProps> = ({
  columns,
  listToCompare,
  getClassForColumn,
}) => {
  return (
    <p>
      {columns.map((column, index) => {
        const currentKey = typeof column === "string" ? column : column.name;
        return (
          <React.Fragment key={currentKey}>
            <span className={`compared-list ${getClassForColumn(currentKey, listToCompare)}`}>
              {currentKey}
            </span>
            {index < columns.length - 1 ? ", " : ""}
          </React.Fragment>
        );
      })}
    </p>
  );
};

const SchemaEditedInfoDialog: React.FunctionComponent<ISchemaEditedInfoDialogProps> = (props) => {
  const leftHeaderContentRef = useRef<HTMLDivElement>(null);
  const rightHeaderContentRef = useRef<HTMLDivElement>(null);

  const leftCountryContentRef = useRef<HTMLDivElement>(null);
  const rightCountryContentRef = useRef<HTMLDivElement>(null);

  const {
    maxHeight: maxHeaderHeight,
    fullHeight: fullHeaderHeight,
    overlapState: overlapHeader,
    isOpenOverlap: isOpenOverlapHeader,
    setIsOpenOverlap: setIsOpenOverlapHeader,
  } = useContentOverlap(leftHeaderContentRef, rightHeaderContentRef);

  const {
    maxHeight: maxCountryHeight,
    fullHeight: fullCountryHeight,
    overlapState: overlapCountry,
    isOpenOverlap: isOpenOverlapCountry,
    setIsOpenOverlap: setIsOpenOverlapCountry,
  } = useContentOverlap(leftCountryContentRef, rightCountryContentRef);

  const originalSnapshotString = sessionStorage.getItem("snapshot");
  const mappingContext = MappingContext();
  const originalSnapshot: Snapshot = originalSnapshotString
    ? JSON.parse(originalSnapshotString)
    : {};

  const handleClose = () => {
    props.setShowDialog(false);
  };

  const getHeaderListNames = (headers: IHeaders[]) => headers.map((obj) => obj.name);

  const getClassMissing = (itemToCheck: string, listToCheck: string[]) => {
    return listToCheck.includes(itemToCheck) ? "" : "missing";
  };

  const getClassAdded = (itemToCheck: string, listToCheck: string[]) => {
    return listToCheck.includes(itemToCheck) ? "" : "added";
  };

  const wasRemovedMapping = (columnName: string, to: string) => {
    if (!mappingContext.fromTo.length) return false;
    return !mappingContext.fromTo.some(
      (column) => column.from.columnName === columnName && column.to === to
    );
  };

  const wasAddedMapping = (columnName: string, to: string) => {
    return !originalSnapshot.fromTo.some(
      (column) => column.from.columnName === columnName && column.to === to
    );
  };

  if (!originalSnapshot?.dataComponent) {
    return (
      <SDialog
        id="SchemaEditedInfo"
        onS-dismiss={(e) => props.setShowDialog(false)}
        open={props.showDialog}
      >
        <div slot="header" id="dialog-title" data-testid="dialog-separator-id">
          Schema Edited Info Dialog
        </div>
        <div slot="body">
          <SAlert status="error" noDismiss>
            <div>Unable to retrieve saved information from Schema.</div>
          </SAlert>
        </div>
        <div slot="footer">
          <button
            type="button"
            className="primary small"
            data-testid="close-error-message"
            onClick={handleClose}
          >
            Ok
          </button>
        </div>
      </SDialog>
    );
  }

  return (
    <SDialog
      id="SchemaEditedInfo"
      onS-dismiss={(e) => props.setShowDialog(false)}
      open={props.showDialog}
      className="overflow-visible with-80-percent"
    >
      <div slot="header" id="dialog-title" data-testid="dialog-separator-id">
        Schema Edited Info Dialog
      </div>
      <div slot="body">
        <div className="columns">
          <div className="column">
            <h3>Saved data</h3>
          </div>

          <div className="column">
            <h3>Changes</h3>
          </div>
        </div>

        <div style={{ maxHeight: "400px", overflow: "auto" }}>
          <div className="columns">
            <div className="column">
              <section>
                <h4>File</h4>
                <p>
                  Name:{" "}
                  <span
                    className={`compared-list ${getClassMissing(
                      originalSnapshot.dataComponent.file.name,
                      [mappingContext.dataComponent.file.name]
                    )}`}
                  >
                    {originalSnapshot.dataComponent.file.name}
                  </span>
                </p>
                <p>
                  Size:{" "}
                  <span
                    className={`compared-list ${getClassMissing(
                      originalSnapshot.dataComponent.file.size,
                      [mappingContext.dataComponent.file.size]
                    )}`}
                  >
                    {originalSnapshot.dataComponent.file.size}
                  </span>
                </p>
                <p>
                  Type:{" "}
                  <span
                    className={`compared-list ${getClassMissing(
                      originalSnapshot.dataComponent.file.type,
                      [mappingContext.dataComponent.file.type]
                    )}`}
                  >
                    {originalSnapshot.dataComponent.file.type}
                  </span>
                </p>
                <p>
                  Last modified:{" "}
                  <span
                    className={`compared-list ${getClassMissing(
                      originalSnapshot.dataComponent.file.lastModified,
                      [mappingContext.dataComponent.file.lastModified]
                    )}`}
                  >
                    {originalSnapshot.dataComponent.file.lastModified}
                  </span>
                </p>
              </section>

              <section>
                <h4>Headers</h4>
                <div
                  ref={leftHeaderContentRef}
                  className="highlighted-list-content"
                  style={{
                    maxHeight: isOpenOverlapHeader ? fullHeaderHeight : maxHeaderHeight,
                  }}
                >
                  <ComparedHeaderList
                    columns={originalSnapshot.headers}
                    getClassForColumn={getClassMissing}
                    listToCompare={getHeaderListNames(mappingContext.headers)}
                  />
                </div>

                <OverlapEffect
                  isOpenOverlap={isOpenOverlapHeader}
                  setIsOpenOverlap={setIsOpenOverlapHeader}
                  showOverlapEffect={overlapHeader === "saved"}
                />
              </section>
              {!mappingContext.disableCountryStep && (
                <>
                  <section>
                    <h4>Selected Type</h4>
                    <div className="highlighted-list-content">
                      <ComparedHeaderList
                        columns={originalSnapshot.selectedTypes}
                        getClassForColumn={getClassMissing}
                        listToCompare={mappingContext.selectedTypes}
                      />
                    </div>
                  </section>

                  <section>
                    <h4>Selected Countries</h4>
                    <div
                      ref={leftCountryContentRef}
                      className="highlighted-list-content"
                      style={{
                        maxHeight: isOpenOverlapCountry ? fullCountryHeight : maxCountryHeight,
                      }}
                    >
                      <ComparedHeaderList
                        columns={originalSnapshot.selectedCountries}
                        getClassForColumn={getClassMissing}
                        listToCompare={mappingContext.selectedCountries}
                      />
                    </div>

                    <OverlapEffect
                      isOpenOverlap={isOpenOverlapCountry}
                      setIsOpenOverlap={setIsOpenOverlapCountry}
                      showOverlapEffect={overlapCountry === "saved"}
                    />
                  </section>
                </>
              )}

              <section>
                <h4>Mappings</h4>
                {originalSnapshot.fromTo.map((column) => (
                  <div key={column.id}>
                    {column.from.columnName}
                    {" : "}
                    <span
                      className={`${
                        wasRemovedMapping(column.from.columnName, column.to) ? "missing" : ""
                      }`}
                    >
                      {column.to}
                    </span>
                  </div>
                ))}
              </section>
            </div>

            <div className="column">
              <section>
                <h4>File</h4>
                <p>
                  Name:{" "}
                  <span
                    className={`${getClassAdded(originalSnapshot.dataComponent.file.name, [
                      mappingContext.dataComponent.file.name,
                    ])}`}
                  >
                    {mappingContext.dataComponent.file.name}
                  </span>
                </p>
                <p>
                  Size:{" "}
                  <span
                    className={`${getClassAdded(originalSnapshot.dataComponent.file.size, [
                      mappingContext.dataComponent.file.size,
                    ])}`}
                  >
                    {mappingContext.dataComponent.file.size}
                  </span>
                </p>
                <p>
                  Type:{" "}
                  <span
                    className={`${getClassAdded(originalSnapshot.dataComponent.file.type, [
                      mappingContext.dataComponent.file.type,
                    ])}`}
                  >
                    {mappingContext.dataComponent.file.type}
                  </span>
                </p>
                <p>
                  Last modified:{" "}
                  <span
                    className={`${getClassAdded(originalSnapshot.dataComponent.file.lastModified, [
                      mappingContext.dataComponent.file.lastModified,
                    ])}`}
                  >
                    {mappingContext.dataComponent.file.lastModified}
                  </span>
                </p>
              </section>

              <section style={{ position: "relative" }}>
                <h4>Headers</h4>
                <div
                  ref={rightHeaderContentRef}
                  className="highlighted-list-content"
                  style={{
                    maxHeight: isOpenOverlapHeader ? fullHeaderHeight : maxHeaderHeight,
                  }}
                >
                  <ComparedHeaderList
                    columns={mappingContext.headers}
                    getClassForColumn={getClassAdded}
                    listToCompare={getHeaderListNames(originalSnapshot.headers)}
                  />
                </div>

                <OverlapEffect
                  isOpenOverlap={isOpenOverlapHeader}
                  setIsOpenOverlap={setIsOpenOverlapHeader}
                  showOverlapEffect={overlapHeader === "changes"}
                />
              </section>

              {!mappingContext.disableCountryStep && (
                <>
                  <section>
                    <h4>Selected Type</h4>
                    <div className="highlighted-list-content">
                      <ComparedHeaderList
                        columns={mappingContext.selectedTypes}
                        getClassForColumn={getClassAdded}
                        listToCompare={originalSnapshot.selectedTypes}
                      />
                    </div>
                  </section>

                  <section>
                    <h4>Selected Countries</h4>
                    <div
                      ref={rightCountryContentRef}
                      className="highlighted-list-content"
                      style={{
                        maxHeight: isOpenOverlapCountry ? fullCountryHeight : maxCountryHeight,
                      }}
                    >
                      <ComparedHeaderList
                        columns={mappingContext.selectedCountries}
                        getClassForColumn={getClassAdded}
                        listToCompare={originalSnapshot.selectedCountries}
                      />
                    </div>

                    <OverlapEffect
                      isOpenOverlap={isOpenOverlapCountry}
                      setIsOpenOverlap={setIsOpenOverlapCountry}
                      showOverlapEffect={overlapCountry === "changes"}
                    />
                  </section>
                </>
              )}

              <section>
                <h4>Mappings</h4>
                {!mappingContext.fromTo.length &&
                  originalSnapshot.fromTo.map((column) => (
                    <div key={column.id}>
                      {column.from.columnName}
                      {" : "}
                      <span>{column.to}</span>
                    </div>
                  ))}

                {mappingContext.fromTo.map((column) => (
                  <div key={column.id}>
                    <span
                      title={!column.from.mandatory ? "Not mandatory" : ""}
                      style={{ fontWeight: 400 }}
                      className={`${!column.from.mandatory ? "isOptional" : ""}`}
                    >
                      {column.from.columnName}
                    </span>
                    {" : "}
                    <span
                      className={`${
                        wasAddedMapping(column.from.columnName, column.to) ? "added" : ""
                      }`}
                    >
                      {column.to}
                    </span>
                  </div>
                ))}
              </section>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer">
        <button
          type="button"
          className="primary small"
          data-testid="button-confirm-id"
          onClick={handleClose}
        >
          Ok
        </button>
      </div>
    </SDialog>
  );
};

export default SchemaEditedInfoDialog;
