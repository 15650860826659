import { SDialog } from "@avalara/skylab-sdk/react";

import React from "react";

type Props = {
  title: string;
  content: string | JSX.Element;
  showDialog: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmDialog: React.FunctionComponent<Props> = (props) => {
  return (
    <SDialog open={props.showDialog} id="generic-dialog" onS-dismiss={props.onCancel}>
      <div slot="header" id="dialog-title">
        {props.title}
      </div>
      <div slot="body">{props.content}</div>
      <div slot="footer">
        <button className="secondary small" onClick={props.onCancel}>
          Cancel
        </button>
        <button className="primary small" onClick={props.onConfirm}>
          Save
        </button>
      </div>
    </SDialog>
  );
};

export default ConfirmDialog;
